import React, { useEffect, useCallback, useState, useRef } from "react";
import styles from "./index.module.scss";
import * as types from "constants/actionTypes";
import { StoreTypes, useStore } from "context";
import VideoProgress from "components/VideoContent/VideoProgress";
import Loading from "components/Loading";
import { API } from "api";
import classnames from "classnames";
import { useUpdateVideoTime } from "customHooks/updateVideoTime";
import { timeFormatTommss } from "util/timeFormater";
import moment from 'moment'

let videoInterval;
let playInfo;

const OpenLinkContent = ({ productCode,purchaseTime }) => {
    const checkoutProductcode = (purchaseCode, code) => {
        return purchaseCode ? purchaseCode.find((info) => info.productCode === code) : false;
    };
    const [{ videoList, recordData, videoSrc, isTryOut }, globalDispatch] = useStore(StoreTypes.global);
    const [{ token, product, isOneLiveMember }] = useStore(StoreTypes.user);
    const [videoInfo, setVideoInfo] = useState(null);
    const [progressInfo, setProgressInfo] = useState(null);
    const videoRef = useRef();
    const { updateTime } = useUpdateVideoTime();
    const [videosTime, setVideosTime] = useState([]);
    const [test, setTest] = useState([]);
    const [isPurchase, setIsPurchase] = useState(checkoutProductcode(product, productCode) &&
    (moment.unix(purchaseTime).isAfter(moment()) || isOneLiveMember));

    useEffect(() => {
        (async () => {
            if (videoList && videoList.length <= 0) return;
            const ids = [];
            for (var key in videoList) {
                ids.push(videoList[key]["code"]);
            }
            const result = await API.post(
                `${process.env.REACT_APP_API_DOMAIN}/video/getUserVideoWatchTime`,
                {
                    id: ids,
                },
                token
            );
            console.log("result", result);
            if (result.status === "success") {
                setProgressInfo(result.data);
            } else {
                setProgressInfo([]);
            }
        })();
        return () => {
            setVideosTime([])
        }
    }, [videoList, token]);

    useEffect(() => {
        if (!progressInfo) return;
        setTest(videosTime)
        videosTime.length > 0 && videosTime.forEach((info, index) => {
            const { time, id } = info
            const timeEle = document.getElementById(`videoTime_${id.split("_")[1]}`)
            if (timeEle) {
                timeEle.textContent = `時間:${time}`
            }
        })
    }, [videosTime, progressInfo])

    const createVideoList = useCallback((section, progressInfo) => {
        let elem = [];
        let keyCount = 0;
        for (var key in section) {
            const data = section[key];
            const isDemo = data.demo;
            elem.push(
                <div key={key} className={styles.list}>
                    <div className={styles.icon}>
                        <img src="./assets/img/WritingNotes.svg" alt="" />
                    </div>
                    <div className={styles.text}>
                        <div className={styles.content}>
                            <div className={styles.text1}>{data.name}</div>
                        </div>
                    </div>
                    <div
                        className={classnames(styles.testBtn, {
                            [styles.hide]: !isPurchase && isDemo === "N",
                        })}
                        onClick={() => openLink(data)}
                    >
                        <div>
                            前往連結
                        </div>
                    </div>
                </div>
            );
            keyCount++;
        }
        return elem;
    }, [isTryOut, videosTime, test]);

    const initPlayInfo = useCallback(() => {
        if (!videoRef.current || !videoInfo || !productCode) return;
        const videoCurrent = videoRef.current;
        const id = videoInfo["code"];
        const name = videoInfo["name"];

        //{"id":"BBBBB","time":1.203944,"duration":160.751746 ,"name":"BB"}
        let currentTime = videoCurrent.currentTime
        const info = { id, time: currentTime, duration: 0, name, productCode };
        info.duration = videoCurrent.duration;
        info.time = videoCurrent.currentTime;
        playInfo = info

        clearInterval(videoInterval);
        videoInterval = setInterval(() => {
            info.duration = videoCurrent.duration;
            info.time = videoCurrent.currentTime;
            playInfo = info
        }, 200);
    }, [videoRef, videoInfo, productCode])

    const savePartInfo = useCallback(async () => {
        clearInterval(videoInterval);
        productCode && await updateTime(playInfo);
    }, [])

    useEffect(() => {
        if (!isTryOut && !videoRef.current) return;
        const videoCurrent = videoRef.current;
        videoCurrent && videoCurrent.addEventListener('pause', savePartInfo);
        videoCurrent && videoCurrent.addEventListener('play', initPlayInfo);

        return async () => {
            videoCurrent && videoCurrent.removeEventListener('pause', savePartInfo);
            videoCurrent && videoCurrent.removeEventListener('play', initPlayInfo);
            clearInterval(videoInterval);
            if (playInfo) {
                const { time, duration } = playInfo
                if (Math.round(time) < Math.round(duration)) {
                    savePartInfo()
                }
            }
            playInfo = null;
        };
    }, [videoRef, videoSrc]);

    const openLink = useCallback((data) => {
        // {
        //     id:"112_00_00_TT_A1_1_0210",
        //     name:"高中地球科學MR主題包_聖嬰現象學習單",
        //     productCode:"112_00_00_TT_A1"
        // }
        const info = {
            id: data.id,
            name: data.name,
            productCode: data.productcode
        }
        fetch(`${process.env.REACT_APP_API_DOMAIN}/video/setUserExternalLinkClick`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify(info)
        })
        window.open(`${data.url}?2s8-5c4-ej!$Mg5-4Dkc56-4c54`)
    },[token])

    return (
        <div className={styles.videoListContent}>
            {videoSrc && (
                <div className={styles.videoPlayContent}>
                    <div className={styles.videoPlay}>
                        <video
                            ref={videoRef}
                            className={styles.videoPlay}
                            src={videoSrc}
                            autoPlay
                            controls
                            controlsList="nodownload"
                            disablePictureInPicture
                        />
                    </div>
                </div>
            )}

            <div>
                {videoList.length > 0 && (
                    <div className={styles.OpenLinkContentTitle}>外開連結</div>
                )}
                <div className={styles.listContent}>
                    {progressInfo ? (
                        createVideoList(videoList, progressInfo)
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        </div>
    );
};

export default OpenLinkContent;
