
import { timeFormatTommss, timeFormatTohhmmss } from "util/timeFormater";
import {
    exportUsersExcelHeaders,
    SECOND_TAB_NAME,
    Grade_String
} from "constants/studentUserInfoTypes";
import dayjs from "dayjs";

export const exportJSONToCSV = (csvTitleTab, jsonPath) => {
    (async () => {
        let row = [];
        row.push(csvTitleTab);
        const users = await fetch(jsonPath)
            .then(response => response.json())
            .catch(error => console.error(error));

        const schoolPromise = []
        users.users.forEach((user) => {
            schoolPromise.push(new Promise(async (resolve, reject) => {
                const res = await fetch(
                    `https://oidc.tanet.edu.tw/moeresource/api/v2/eduoid/all/eduid/${user.schoolid}`,
                    { method: "GET" }
                ).then((res) => res.json()).catch(err => {
                    console.log(err);
                });
                if (res) {
                    resolve({ ...user, id: user.schoolid, schoolname: res[0] ? res[0].schoolname : '未知' });
                } else {
                    resolve({ ...user, id: user.schoolid, schoolname: '未知' })
                }
            }))
        })
        await Promise.all(schoolPromise).then(async (results) => {
            results = results.filter(res => res.schoolname !== "未知")
            results.forEach((info, index) => {
                const {
                    grade,
                    classno,
                    schoolname,
                    username,
                    inputName1,
                    inputName2,
                    videoNum,
                    watchMinute,
                    testNum,
                    score,
                } = info;

                row.push([
                    schoolname,
                    grade,
                    classno,
                    username,
                    inputName1 + inputName2,
                    videoNum,
                    timeFormatTohhmmss(watchMinute),
                    testNum,
                    score,
                ]);
            });

            let csvContent =
                "data:text/csv;charset=utf-8,\uFEFF" +
                row.map((e) => e.join(",")).join("\n");
            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        });
    })()
}

const splitArrayByIndexRange = (array, range) => {
    const result = [];

    for (let i = 0; i < array.length; i += range) {
        const chunk = array.slice(i, i + range);
        result.push(chunk);
    }

    return result;
}

let exportCount = 0
let infoSlice = []
const findRealCount = ({ userInfo, key }) => {
    const realInfo = userInfo.filter(info => {
        if (info.schoolInfo && info.hadUsedProduct) {
            const { schoolCounty, schoolDistrict, schoolName } = info.schoolInfo
            if (schoolCounty + schoolDistrict + schoolName === key) {
                return info
            }
        }
    })
    return realInfo.length;
}

const exportStep = ({ infos, exportCount, currentTab, secondTab, userInfo }) => {
    setTimeout(() => {
        const row = [];
        const targetKey = Object.keys(exportUsersExcelHeaders).find(key => exportUsersExcelHeaders[key].tabType === secondTab);
        const headers = exportUsersExcelHeaders[targetKey].headers
        const headerKeys = Object.keys(headers)
        const headerArray = []
        headerKeys.forEach(key => {
            headerArray.push(headers[key]);
        });
        row.push(headerArray)

        let userInfos = userInfo ? infos && infos.map((data) => {
            return { ...data, ...userInfo.find(user => user.username === data.username) }
        }) : infos;
        switch (secondTab) {
            case '概覽':
                userInfos.forEach((info, index) => {
                    const {
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        username,
                        inputName1,
                        inputName2,
                        videoNum,
                        watchMinute,
                        testNum,
                        score,
                        clickCounts
                    } = info;

                    const id = [username.substr(0, 1) === '0' ? `*${username}` : username]
                    row.push([
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        id,
                        inputName1 + inputName2,
                        videoNum ? `${videoNum}部` : 0,
                        parseInt(watchMinute),
                        testNum ? `${testNum}次` : 0,
                        score ? `${parseInt(score)}分` : '0分',
                        clickCounts
                    ]);
                });
                break;
            case '影片觀看紀錄':
                userInfos.forEach((info, index) => {
                    const {
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        username,
                        inputName1,
                        inputName2,
                        video,
                    } = info;
                    const id = [username.substr(0, 1) === '0' ? `*${username}` : username]

                    row.push([
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        id,
                        inputName1 + inputName2,
                        video.timestamp ? dayjs(video.timestamp).format("YYYY/MM/DD HH:mm:ss") : '00:00:00',
                        video ? ((video.volume || "") + video.name) : '',
                        `${timeFormatTommss(video.time ? video.time : 0)}/${timeFormatTommss(
                            video.duration ? video.duration : 0
                        )}`
                    ]);
                });
                break;
            case '測驗完成紀錄':
                userInfos.forEach((info, index) => {
                    const {
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        username,
                        inputName1,
                        inputName2,
                        sumTime,
                        exam,
                    } = info;
                    const id = [username.substr(0, 1) === '0' ? `*${username}` : username]

                    row.push([
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        id,
                        inputName1 + inputName2,
                        exam.examCreateDate ? dayjs(exam.examCreateDate).format("YYYY/MM/DD HH:mm:ss") : 0,
                        Math.ceil(exam.sumTime / 1000),
                        exam.attribute ? (exam.attribute.name + exam.attribute.bookNames[0] + exam.attribute.examName).replace("學年度", "") : '',
                        exam.score + '分',
                        `${process.env.REACT_APP_ONE_EXAM_DOMAIN}/user/report/${exam.id}/${id}`
                    ]);
                });
                break;
            case '外開連結點擊次數':
                userInfos.forEach((info, index) => {
                    const {
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        username,
                        inputName1,
                        inputName2,
                        externalLink,
                    } = info;
                    const id = [username.substr(0, 1) === '0' ? `*${username}` : username]
                    const { timestamp, volume } = externalLink
                    row.push([
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        classno,
                        id,
                        inputName1 + inputName2,
                        timestamp ? dayjs(timestamp).format("YYYY/MM/DD HH:mm:ss") : 0,
                        volume,
                    ]);
                });
                break;
            case '學校':
                userInfos.forEach((info, index) => {
                    const {
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        studentCount,
                        videoNum,
                        watchMinute,
                        testNum,
                        score,
                        clickCounts
                    } = info;

                    row.push([
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        studentCount,
                        findRealCount({ userInfo, key: schoolCounty + schoolDistrict + schoolName }),
                        videoNum,
                        parseInt(watchMinute),
                        testNum ? testNum : "0",
                        score ? score.toFixed(1) + "分" : "0分",
                        clickCounts
                    ]);
                });
                break;
            case '年級':
                userInfos.forEach((info, index) => {
                    const {
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        studentCount,
                        videoNum,
                        watchMinute,
                        testNum,
                        score,
                        clickCounts
                    } = info;

                    row.push([
                        schoolCounty,
                        schoolDistrict,
                        schoolName,
                        grade,
                        studentCount,
                        findRealCount({ userInfo, key: schoolCounty + schoolDistrict + schoolName + grade }),
                        videoNum,
                        parseInt(watchMinute),
                        testNum ? testNum : "0",
                        score ? score.toFixed(1) + "分" : "0分",
                        clickCounts
                    ]);
                });
                break;
            case '產品縣市':
                userInfos.forEach((info, index) => {
                    const {
                        productName,
                        schoolCounty,
                        userCount,
                        videoNum,
                        watchMinute,
                        testNum,
                        score,
                        clickCounts
                    } = info;

                    row.push([
                        productName,
                        schoolCounty,
                        userCount,
                        videoNum ? videoNum : "0",
                        parseInt(watchMinute),
                        testNum ? testNum : "0",
                        score ? score.toFixed(1) + "分" : "0分",
                        clickCounts
                    ]);
                });
                break;
            case '產品學校':
                userInfos.forEach((info, index) => {
                    const {
                        productName,
                        schoolCounty,
                        schoolName,
                        userCount,
                        videoNum,
                        watchMinute,
                        testNum,
                        score,
                        clickCounts
                    } = info;
                    row.push([
                        productName,
                        schoolCounty,
                        schoolName,
                        userCount,
                        videoNum ? videoNum : "0",
                        parseInt(watchMinute),
                        testNum ? testNum : "0",
                        score ? score.toFixed(1) + "分" : "0分",
                        clickCounts
                    ]);
                });
                break;
            default:
                break;
        }

        let csvContent =
            "data:text/csv;charset=utf-8,\uFEFF" +
            row.map((e) => e.join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", '下載');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        exportCount++
        if (exportCount < infoSlice.length) {
            exportStep({ infos: infoSlice[exportCount], exportCount, currentTab, secondTab, userInfo })
        }
    }, 1000)
}

export const exportJSONToCSV2 = ({ currentTab, secondTab, studentsInfo, userInfo }) => {
    if (!studentsInfo) return;
    infoSlice = splitArrayByIndexRange(studentsInfo, 10000)
    exportCount = 0
    exportStep({ infos: infoSlice[exportCount], exportCount, currentTab, secondTab, userInfo })
}
