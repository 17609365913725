import React, { useState, useCallback, useEffect, createElement } from "react";
import styles from "./index.module.scss";
import { useStore, StoreTypes } from "context";
import { IMAGES } from "constants/imagePath";
import { SUBJECT_TYPE } from "constants/subjectType";
import { API } from "api";
import { style } from "styled-system";
import classnames from "classnames";
import { checkGrade } from "constants/subjectType";
import { checkProjectType } from "constants/projectsType";

const SelectVolumeCard = () => {
	const router = useStore(StoreTypes.router);
	const [{ educationInfo }, globalDispatch] = useStore(StoreTypes.global);
	const [{ token }] = useStore(StoreTypes.user);
	const [volumeType, setVolumeType] = useState("主題式教師專區");
	const [volumeName, setVolumeName] = useState("一上");
	const [cardsInfo, setCardInfo] = useState([])

	const selectVolume = useCallback(
		(education, volume, course) => {
			router.history.push(
				`/ProductView?education=${education}&volume=${volume}&course=${course}`
			);
		},
		[educationInfo, router]
	);

	const setProjectName = () => {
		return checkProjectType() ? "OneStudy" : "NaniStudy"
	}

	const getCardInfo = (volumeType) => {
		const numberTable = {
			'一下': 1,
			'二下': 2,
			'三下': 3,
			'四下': 4,
			'五下': 5,
			'六下': 6,
			'七下': 7,
			'八下': 8,
			'九下': 9,
			'一上': 10,
			'二上': 11,
			'三上': 12,
			'四上': 13,
			'五上': 14,
			'六上': 15,
			'七上': 16,
			'八上': 17,
			'九上': 18,
		}
		// const numberTable = {
		// 	'一上': 11,
		// 	'一下': 12,
		// 	'二上': 21,
		// 	'二下': 22,
		// 	'三上': 31,
		// 	'三下': 32,
		// 	'四上': 41,
		// 	'四下': 42,
		// 	'五上': 51,
		// 	'五下': 52,
		// 	'六上': 61,
		// 	'六下': 62,
		// 	'七上': 71,
		// 	'七下': 72,
		// 	'八上': 81,
		// 	'八下': 82,
		// 	'九上': 91,
		// 	'九下': 92
		// }
		const gradeTranslate = (grade) => {
			return numberTable[grade];
		}
		const volume = educationInfo[volumeType]
		const arr = []
		for (let key in volume) {
			arr.push(key)
		}
		arr.sort(function (a, b) {
			return gradeTranslate(a) - gradeTranslate(b);
		});
		setCardInfo(arr)
	}

	useEffect(() => {
		if (!educationInfo) return;
		getCardInfo(volumeType)
	}, [educationInfo, volumeType, volumeName])

	const createVolumeContent = useCallback(() => {
		if (!volumeType || !educationInfo) return;
		const getSemester = (educationInfoList) => {
			const years = [];
			const volumeAndYears = [];
			educationInfoList.forEach((item) => {
				const year = item.id.slice(0, item.id.indexOf('_'));
				if (years.indexOf(year) === -1) {
					years.push(year);
					volumeAndYears.push(item);
				}
			})
			return volumeAndYears;
		}
		const volumeElementArr = [];
		const semesterAndVolume = [];
		cardsInfo.forEach((volume) => {
			const key = volumeType + volume
			const hasGrade = checkGrade.find((str) => str === key.substr(key.length - 1, 1))
			const filterKeyName = hasGrade ? `${key}年級` : key;

			const isTheme = volumeType === "主題式"
			let volumeStr = isTheme ? volume : `${volume}上`
			if (volumeType === "高中" && !hasGrade) {
				volumeStr = volume
			}
			const { course, product } = educationInfo[volumeType][volume][0]
			//console.log(volume, "volumeName", educationInfo[volumeType][volume]);
			const semestersList = getSemester(educationInfo[volumeType][volume]);
			semestersList.forEach((semester) => semesterAndVolume.push(semester));

		})
		semesterAndVolume.sort((a, b) => {
			const a_year = a.id.split('_').slice(0, 1);
			const b_year = b.id.split('_').slice(0, 1);
			return b_year - a_year
		});

		semesterAndVolume.forEach((item) => {
			volumeElementArr.push(<div
				className={styles.card}
				onClick={() => selectVolume(volumeType, item.VolumeName, item.course)}
			>
				<div>
					<img src={checkProjectType() ? IMAGES.PRODUCT[`${volumeType + item.VolumeName}_S`] : IMAGES.NANI_PRODUCT[`${item.course}`]} alt={volumeType + item.VolumeName} />
				</div>
				<div className={styles.text}>{item.course}</div>
			</div>)
		})
		return volumeElementArr;
	}, [volumeType, educationInfo, cardsInfo, volumeName])

	const createElementarys = (educationInfo) => {
		const lists = []
		const elements = []
		Object.keys(educationInfo).forEach((index) => {
			if (index === 'app') return
			const trees = educationInfo[index]
			const { VolumeName, sort } = trees[Object.keys(trees)[0]][0]
			lists.push({ index, VolumeName, sort })
		})
		lists.sort((a, b) => {
			return parseInt(a.sort) - parseInt(b.sort)
		})

		lists.forEach((list) => {
			elements.push(
				<div
					className={classnames(styles.elementary, {
						[styles.show]: volumeType === list.index,
					})}
					onClick={() => {
						const trees = educationInfo[list.index]

						getCardInfo(list.index)
						setVolumeType(list.index)
						setVolumeName(list.VolumeName)
					}}
				>
					<div>{list.index}</div>
					{volumeType === list.index && (
						<div className={styles.line}></div>
					)}
				</div>
			)
		})

		return elements
	}

	return (
		<div id="SelectVolumeCard" className={styles.selectVolumeCard}>
			<div className={styles.textContent}>
				<div className={styles.text1}>
					<div className={styles.line}></div>
					<div className={styles.text}>{`${setProjectName()} 線上課程`}</div>
				</div>
				<div className={styles.text2}>
					<div>現在就選擇一個年級開始.</div>
				</div>
				<div className={styles.text3}>
					{
						educationInfo && createElementarys(educationInfo)
					}
				</div>
			</div>
			<div className={styles.volumeContent}>
				{
					createVolumeContent()
				}
			</div>
		</div>
	);
};
export default SelectVolumeCard;
