import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import { checkProjectType } from "constants/projectsType";

const Page1 = ({ scrollToAnchor }) => {
	const [, globalDispatch] = useStore(StoreTypes.global);

	useEffect(() => { }, []);

	return (
		<div className={styles.page1}>
			<div className={styles.bg}>
				<div className={styles.home1}>
					<img src={"./assets/img/Home-1.png"} alt="bg" />
				</div>
				<div className={styles.title}>
					<div className={styles.title1}>{`${checkProjectType() ? "OneClass" : "NaniStudy"}線上課程`}</div>
					<div className={styles.title2}>萬課室學習專區</div>
					<div className={styles.title3}>孩子學習一步到位</div>
					<div className={styles.btn} onClick={() => scrollToAnchor("SelectVolumeCard")}>
						<img src="./assets/img/button.svg" alt="" />
					</div>
				</div>
				<div className={styles.home2}>
					<img src={"./assets/img/Home-2.png"} alt="bg" />
				</div>
			</div>
		</div>
	);
};
export default Page1;
