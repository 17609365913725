import React, { useState, useCallback, useEffect } from "react";
import styles from "./index.module.scss";
import useSetState from "customHooks/setState";
import { useStore, StoreTypes } from "context";
import DialogModule from "components/Dialogs/";
import Header from "components/Header";
import Footer from "components/Footer";
import { Beforeunload } from 'components/Beforeunload';
import Page1 from "./Page1";
import SelectVolumeCard from "components/SelectVolumeCard";
import * as actionTypes from "constants/actionTypes";
import { checkProjectType } from "constants/projectsType";
import { useHistory } from "react-router-dom";

const MainPage = () => {
	const [, globalDispatch] = useStore(StoreTypes.global);
	const history = useHistory();

	const getCookie = (name) => {
		const value = `; ${document.cookie}`;
		const parts = value.split(`; ${name}=`);
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	useEffect(() => {
		let redirect = getCookie("redirect");
		redirect && history.push(redirect);
	}, [history]);

	useEffect(() => {
		globalDispatch({
			type: actionTypes.SET_NOW_HEAD_LIST_SELECT_NAME,
			nowHeadSelectListName: "",
		});

	}, [globalDispatch]);

	const scrollToAnchor = (anchorName) => {
		if (anchorName) {
			let anchorElement = document.getElementById(anchorName);
			if (anchorElement) {
				anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	};

	return (
		<Beforeunload>
			<div>
				<Header />
				<div className={styles.mainPage}>
					<Page1 scrollToAnchor={scrollToAnchor} />
					<div className={styles.page2}>
						<div className={styles.bg}>
							<img src={`./assets/img${checkProjectType() ? '' : '/nanistudy'}/DARK FEATURES.png`} alt="bg" />
						</div>
					</div>
					<div className={styles.page3}>
						<div className={styles.bg}>
							<img src={`./assets/img${checkProjectType() ? '' : '/nanistudy'}/Content.png`} alt="bg" />
						</div>
						<div
							className={styles.btn}
							onClick={() => scrollToAnchor("SelectVolumeCard")}
						>
							<img src={"./assets/img/button.svg"} alt="button2" />
						</div>
					</div>
					<div className={styles.page4}>
						<div className={styles.bg}>
							<img src={"./assets/img/All.png"} alt="All" />
						</div>
						<div
							className={styles.btn}
							onClick={() => scrollToAnchor("SelectVolumeCard")}
						>
							<img src={"./assets/img/button2.svg"} alt="button2" />
						</div>
					</div>
					<SelectVolumeCard />
					<Footer />
				</div>
			</div>
		</Beforeunload>
	);
};
export default MainPage;
